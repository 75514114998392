<template>
  <div>
    <div v-if="dataHistory && dataHistory.length">
      <div class="pb-6" style="font-size: 22px; font-weight: bold">
        <v-icon style="color: black" size="36"> mdi-history</v-icon> Lịch sử cập
        nhật hợp đồng
      </div>
      <v-row>
        <v-col cols="6" v-for="(item, index) in this.dataHistory" :key="index">
          <v-card class="mx-auto" elevation="6">
            <v-card-title>
              <v-icon class="pr-3" size="30">mdi-calendar-clock</v-icon>
              Cập nhật lần {{ index + 1 }} - Lúc
              {{ formatDateTime(item.updated_at) }}
            </v-card-title>
            <v-card-text>
              <div>
                Mã hợp đồng: <b>{{ item.contract_code }}</b>
              </div>
              <div>
                Ngày hiệu lực: <b>{{ item.contract_date }}</b>
              </div>
              <div>
                Ngày hết hạn: <b>{{ item.contract_due_date }}</b>
              </div>
              <div>
                Loại hợp đồng: <b>{{ item.contract_type_name }}</b>
              </div>
              <div>
                Khách hàng: <b>{{ item.customer_name }}</b>
              </div>
              <div>
                Đính kèm
                <div
                  class="mt-2 flex-grow-1"
                  v-if="item.files && item.files.length > 0"
                >
                  <CustomTable
                    :headers="headerUpload"
                    :items="item.files"
                    hide-default-footer
                    hide-default-header
                    classPaging="mt-2"
                    :page.sync="pageFile"
                    @change-page="
                      (val) => {
                        pageFile = val;
                      }
                    "
                  >
                    <template v-slot:[`item.icon`]="{ item }">
                      <div :title="item.stt">
                        <v-icon size="40" color="var(--primary-color)"
                          >mdi-file-excel-outline</v-icon
                        >
                      </div>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <div class="my-2">
                        <div class="d-flex justify-space-between align-center">
                          <div
                            class="d-flex align-center"
                            style="min-width: 170px"
                          >
                            <span>{{ item.name }}</span>
                          </div>
                        </div>
                        <div
                          class="mt-2"
                          v-html="
                            `<strong>Size:</strong> ${formatSize(
                              item.size
                            )} | <strong>Type:</strong> ${item.name
                              .split('.')
                              .pop()}`
                          "
                        ></div>
                      </div> </template
                  ></CustomTable>
                </div>
              </div>
              <br />
              <div>
                Ghi chú: <b>{{ item.description }}</b>
              </div>
            </v-card-text>
            <div class="pa-3">
              <div class="pb-2">Sản phẩm</div>

              <CustomTable
                class="mb-2"
                :headers="headers"
                :items="item.contract_details"
                hide-default-footer
                classPaging="mt-2"
                :page.sync="page"
                @change-page="
                  (val) => {
                    page = val;
                  }
                "
              >
                <template v-slot:[`item.product_image`]="{ item }">
                  <img
                    height="50px"
                    width="50px"
                    :src="
                      item.url_image
                        ? item.url_image
                        : item.image && getImageUrl(item.image.link)
                    "
                    v-if="item.image || item.url_image"
                  />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </CustomTable>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-layout v-else justify-center align-center style="height: 600px" column>
      <v-icon class="pr-3" size="80" color="pink">mdi-calendar-clock</v-icon>
      <div class="pt-4" style="font-size: 20px">Không có lịch sử cập nhật</div>
    </v-layout>
  </div>
</template>

<script>
import { byteFormat } from "@/utils/size";

export default {
  props: ["dataHistory"],

  data: () => ({
    page: 1,
    pageFile: 1,
    headerUpload: [
      { text: "icon", align: "start", value: "icon", class: "pl-1 pr-0" },
      { text: "Tên", align: "start", value: "name" },
      { text: "Hành động", align: "end", value: "actions", width: "200px" },
    ],
    headers: [
      {
        text: "Tên sản phẩm",
        align: "start",
        sortable: false,
        value: "product_name",
      },
      { text: "ĐVT", value: "product_unit" },
      { text: "Đơn giá", value: "product_price" },
      { text: "Hình ảnh", value: "product_image" },
      { text: "Ghi chú", value: "description" },
    ],
  }),
  methods: {
    formatDateTime(e) {
      try {
        let time = new Date(e);
        let date = +time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
        let month =
          +time.getUTCMonth() + 1 < 10
            ? "0" + (+time.getUTCMonth() + 1)
            : +time.getUTCMonth() + 1;
        let year = +time.getUTCFullYear();
        let hour =
          +time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
        let min =
          +time.getUTCMinutes() < 10
            ? "0" + time.getUTCMinutes()
            : time.getUTCMinutes();
        return hour + ":" + min + " ngày " + date + "/" + month + "/" + year;
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    formatSize(value) {
      return byteFormat(value);
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
  },
};
</script>

<style>
</style>