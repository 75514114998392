var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataHistory && _vm.dataHistory.length)?_c('div',[_c('div',{staticClass:"pb-6",staticStyle:{"font-size":"22px","font-weight":"bold"}},[_c('v-icon',{staticStyle:{"color":"black"},attrs:{"size":"36"}},[_vm._v(" mdi-history")]),_vm._v(" Lịch sử cập nhật hợp đồng ")],1),_c('v-row',_vm._l((this.dataHistory),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"6"}},[_c('v-card-title',[_c('v-icon',{staticClass:"pr-3",attrs:{"size":"30"}},[_vm._v("mdi-calendar-clock")]),_vm._v(" Cập nhật lần "+_vm._s(index + 1)+" - Lúc "+_vm._s(_vm.formatDateTime(item.updated_at))+" ")],1),_c('v-card-text',[_c('div',[_vm._v(" Mã hợp đồng: "),_c('b',[_vm._v(_vm._s(item.contract_code))])]),_c('div',[_vm._v(" Ngày hiệu lực: "),_c('b',[_vm._v(_vm._s(item.contract_date))])]),_c('div',[_vm._v(" Ngày hết hạn: "),_c('b',[_vm._v(_vm._s(item.contract_due_date))])]),_c('div',[_vm._v(" Loại hợp đồng: "),_c('b',[_vm._v(_vm._s(item.contract_type_name))])]),_c('div',[_vm._v(" Khách hàng: "),_c('b',[_vm._v(_vm._s(item.customer_name))])]),_c('div',[_vm._v(" Đính kèm "),(item.files && item.files.length > 0)?_c('div',{staticClass:"mt-2 flex-grow-1"},[_c('CustomTable',{attrs:{"headers":_vm.headerUpload,"items":item.files,"hide-default-footer":"","hide-default-header":"","classPaging":"mt-2","page":_vm.pageFile},on:{"update:page":function($event){_vm.pageFile=$event},"change-page":function (val) {
                      _vm.pageFile = val;
                    }},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
                    var item = ref.item;
return [_c('div',{attrs:{"title":item.stt}},[_c('v-icon',{attrs:{"size":"40","color":"var(--primary-color)"}},[_vm._v("mdi-file-excel-outline")])],1)]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"170px"}},[_c('span',[_vm._v(_vm._s(item.name))])])]),_c('div',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(
                          ("<strong>Size:</strong> " + (_vm.formatSize(
                            item.size
                          )) + " | <strong>Type:</strong> " + (item.name
                            .split('.')
                            .pop()))
                        )}})])]}}],null,true)})],1):_vm._e()]),_c('br'),_c('div',[_vm._v(" Ghi chú: "),_c('b',[_vm._v(_vm._s(item.description))])])]),_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"pb-2"},[_vm._v("Sản phẩm")]),_c('CustomTable',{staticClass:"mb-2",attrs:{"headers":_vm.headers,"items":item.contract_details,"hide-default-footer":"","classPaging":"mt-2","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"change-page":function (val) {
                  _vm.page = val;
                }},scopedSlots:_vm._u([{key:"item.product_image",fn:function(ref){
                var item = ref.item;
return [(item.image || item.url_image)?_c('img',{attrs:{"height":"50px","width":"50px","src":item.url_image
                      ? item.url_image
                      : item.image && _vm.getImageUrl(item.image.link)}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1)],1)}),1)],1):_c('v-layout',{staticStyle:{"height":"600px"},attrs:{"justify-center":"","align-center":"","column":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"size":"80","color":"pink"}},[_vm._v("mdi-calendar-clock")]),_c('div',{staticClass:"pt-4",staticStyle:{"font-size":"20px"}},[_vm._v("Không có lịch sử cập nhật")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }