<template>
    <FormDialog
      :title="title"
      max-width="500px"
      @submit="submit"
      v-model="dialog"
      class="form"
    >
      <v-row class="row-dialog">
        <v-col cols="12">
          <div class="label-form">Tên công ty</div>
          <v-select
            prepend-inner-icon="mdi-home"
            v-model="form.type"
            :items="ListCompany"
            outlined
            dense
            clearable
            item-text="name"
            item-value="id"
            :rules="ruleListCompany"
          >
          </v-select>
        </v-col>
      </v-row>
    </FormDialog>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
    },
    data: () => ({
      dialog: false,
      ListCompany: [],
      language: null,
      key_title: null,
      form: {
        type: null,
      },
      ruleListCompany: [(v) => !!v || "Công ty in hợp đồng không thẻ bỏ trống"],
    }),
    mounted() {},
    methods: {
      show(item, language, key_title) {
        this.dialog = true;
        this.ListCompany = [...item];
        this.language = language;
        this.key_title = key_title;
      },
      close() {
        this.dialog = false;
      },
      submit() {
        this.$emit("submit", {
          data: this.form,
          language: this.language,
          key_title: this.key_title,
        });
        this.close();
      },
    },
  };
  </script>
  
  <style scoped>
  .row-dialog {
    margin-top: -40px;
  }
  </style>
  