var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormDialog',{attrs:{"readonly":_vm.readonly,"title":_vm.title,"max-width":"1500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CustomTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.ngay",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.ngay)))])]}},{key:"item.xe_dieu_phoi.ca_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.mapCaLamViec(item.xe_dieu_phoi.ca_id)))])]}},{key:"item.xe_dieu_phoi.bien_kiem_soat",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.xe_dieu_phoi.dong_xe + "/" + item.xe_dieu_phoi.bien_kiem_soat))])]}},{key:"item.xe_dieu_phoi.hang_ban",fn:function(ref){
var item = ref.item;
return [(item.xe_dieu_phoi.hang_ban)?_c('span',[_vm._v(" "+_vm._s(item.xe_dieu_phoi.hang_ban)+" ")]):_c('span',[_vm._v(_vm._s(item.xe_dieu_phoi.hang_hoa_chinh))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var itemDetail = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-tabs',[_c('v-tab',[_c('span',{staticStyle:{"color":"black"}},[_vm._v("Chi tiết hàng hóa")])]),_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.tableExpandedHeaders,"items":itemDetail.chi_tiet_phieu_mua_hangs,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.stt",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.new_chat_thai.loai_chat_thai_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapLoaiChatThai(item.new_chat_thai.loai_chat_thai_id))+" ")]}},{key:"body.append",fn:function(ref){
var items = ref.items;
return [_c('tr',{staticClass:"blue-grey lighten-5"},[_c('td'),_c('td',[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v("Tổng khối lượng")])]),_c('td'),_c('td',[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v(_vm._s(_vm.totalWeight(items, "gia_tri")))])]),_c('td')])]}}],null,true)})],1)],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }