<template>
    <FormDialog 
        :readonly="readonly" 
        :title="title" 
        v-model="dialog" 
        max-width="1500px"
    >
        <CustomTable
            :headers="tableHeaders" 
            :items="tableData" 
            :single-expand="singleExpand" 
            :expanded.sync="expanded"
            item-key="id" 
            show-expand
        >
            <template v-slot:[`item.ngay`]="{ item }">
                <span>{{ dateFormat(item.ngay) }}</span>
            </template>
            <template v-slot:[`item.xe_dieu_phoi.ca_id`]="{ item }">
                <span>{{ mapCaLamViec(item.xe_dieu_phoi.ca_id) }}</span>
            </template>
            <template v-slot:[`item.xe_dieu_phoi.bien_kiem_soat`] ="{ item }">
                <span>{{ item.xe_dieu_phoi.dong_xe + "/" + item.xe_dieu_phoi.bien_kiem_soat }}</span>
            </template>
            <template v-slot:[`item.xe_dieu_phoi.hang_ban`] ="{ item }">
                <span
                    v-if="item.xe_dieu_phoi.hang_ban"
                >
                    {{ item.xe_dieu_phoi.hang_ban }}
                </span>
            <span v-else>{{ item.xe_dieu_phoi.hang_hoa_chinh }}</span>
            </template>
            <template v-slot:expanded-item="{ headers, item: itemDetail }">
                <td 
                    :colspan="headers.length" 
                    class="pa-0 ma-0"
                >
                    <v-tabs>
                        <v-tab>
                            <span style=" color: black">Chi tiết hàng hóa</span>
                        </v-tab>
                        <v-tab-item>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-data-table 
                                        dense
                                        :headers="tableExpandedHeaders"
                                        :items="itemDetail.chi_tiet_phieu_mua_hangs"
                                        :hide-default-footer=true
                                    >
                                        <template v-slot:[`item.stt`]="{ index }">
                                            {{ index + 1 }}
                                        </template>
                                        <template v-slot:[`item.new_chat_thai.loai_chat_thai_id`]="{ item }">
                                            {{ mapLoaiChatThai(item.new_chat_thai.loai_chat_thai_id) }}
                                        </template>
                                        <template v-slot:[`body.append`] = "{ items }">
                                            <tr class="blue-grey lighten-5">
                                                <td></td>
                                                <td><span class="text-subtitle-2 font-weight-black">Tổng khối lượng</span></td>
                                                <td></td>
                                                <td><span class="text-subtitle-2 font-weight-black">{{ totalWeight(items, "gia_tri") }}</span></td>
                                                <td></td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>
                </td>
            </template>
        </CustomTable>
    </FormDialog>
</template>
<script>
import { getListReceivedContracts } from "@/api/hopdong.js";
import { formatDate } from "@/utils/date";
export default {
    props: {
        title: {
            type: String,
            default: () => "",
        },
        readonly: Boolean,
    },
    data() {
        return {
            dialog: false,
            expanded: [],
            singleExpand: true,
            tableHeaders: [
                { text: '', value: 'data-table-expand', sortable: false},
                {
                    text: 'Ngày',
                    align: 'start',
                    value: 'ngay',
                },
                { text: 'Ca', value: 'xe_dieu_phoi.ca_id', sortable: false},
                { text: 'Xe(BKS)', value: 'xe_dieu_phoi.bien_kiem_soat', sortable: false},
                { text: 'Tổ trưởng', value: 'xe_dieu_phoi.don_dieu_phoi.to_truong.ten', sortable: false},
                { text: 'Khách hàng', value: 'khach_hang.ten', sortable: false},
                { text: 'Hàng hóa chính', value: 'xe_dieu_phoi.hang_ban', sortable: false},
                { text: 'Tổng KL(kg)', value: 'tong_gia_tri', sortable: false},
                { text: 'KL thực tế(kg)', value: 'khoi_luong_thuc_te', sortable: false},
            ],
            tableExpandedHeaders: [
                {
                    text: 'STT',
                    align: 'start',
                    sortable: false,
                    value: 'stt',
                    width: "5%",
                },
                { text: 'Tên hàng', value: 'new_chat_thai.ten', sortable: false},
                { text: 'Loại hàng', value: 'new_chat_thai.loai_chat_thai_id', sortable: false},
                { text: 'Khối lượng (kg)', value: 'gia_tri', sortable: false},
                { text: 'Ghi chú', value: 'ghi_chu', sortable: false},
            ],
            tableData: [],
        }
    },
    created() {
        if (this.$route.params.id) {
            let id = this.$route.params.id;
            this.getReceivedContracts(id);
        }
    },
    methods: {
        open() {
            this.dialog = true;
        },
        async getReceivedContracts(id) {
            const res = await getListReceivedContracts(id);
            this.tableData=res.data;
        },
        mapCaLamViec(caId) {
            switch (caId) {
                case 1:
                    return "Ca sáng"; 
                case 2:
                    return "Ca chiều";
                case 3:
                    return "Ca tối";
                default:
                    return "";
            }
        },
        mapLoaiChatThai(loaiChatThaiId) {
            switch(loaiChatThaiId) {
                case 1:
                    return "Chất thải nguy hại";
                case 2:
                    return "Phế liệu";
                case 3: 
                    return "Chất thải thông thường";
                default: 
                    return "";
            }
        },
        dateFormat(date) {
            return formatDate(date);
        },
        totalWeight(data, key) {
            return data.reduce((val1, val2) => val1 + (parseInt(val2[key]) || 0), 0);
        }
    },
}
</script>
<style scoped>
</style>


