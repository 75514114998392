<template>
  <div>
    <v-layout
      v-if="data.length == 0 && dataNotUpdate.length == 0"
      justify-center
      align-center
      style="height: 600px"
      column
    >
      <v-icon class="pr-3" size="80">mdi-file-outline</v-icon>
      <div class="pt-4" style="font-size: 20px">Chưa có thêm phụ lục nào</div>
    </v-layout>
    <v-row v-else>
      <v-col cols="6" v-if="dataNotUpdate.length > 0">
        <div class="mb-1"><strong>Danh sách phụ lục cần cập nhật</strong></div>
        <v-card
          class="pa-2"
          outlined
          v-for="(item, index) in dataNotUpdate"
          :key="`contract_not_update_${item.price_code}`"
        >
          <div class="label-form">
            <strong> Sản phẩm - {{ `Mã báo giá: ${item.price_code}` }}</strong>
          </div>
          <v-row>
            <v-col cols="12" class="zero-vertical">
              <CustomTable
                class="mb-2"
                :headers="headers"
                :items="item.product_price_detail"
                hide-default-footer
                :page.sync="pageNPL[index]"
                @change-page="(val) => (pageNPL[index] = val)"
              >
                <template v-slot:[`item.product_image`]="{ item }">
                  <img
                    height="50px"
                    width="50px"
                    :src="
                      item.url_image
                        ? item.url_image
                        : item.image && getImageUrl(item.image.link)
                    "
                    v-if="item.image || item.url_image"
                  />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, index, 'ADD')"
                  >
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </CustomTable>
            </v-col>
          </v-row>
          <div class="d-flex">
            <div class="ml-auto">
              <v-btn depressed color="success" @click="addPL(item)">
                <v-icon left>mdi-plus</v-icon>
                Thêm phụ lục
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col
        v-if="data.length > 0"
        :cols="dataNotUpdate.length > 0 ? `6` : `12`"
      >
        <div class="mb-1"><strong>Danh sách phụ lục kèm thêm</strong></div>
        <v-card
          class="pa-2"
          outlined
          v-for="(item, index) in data"
          :key="`contract_not_update_${item.product_price.price_code}`"
        >
          <div class="label-form d-flex align-center">
            <strong>
              Sản phẩm -
              {{ `Mã báo giá: ${item.product_price.price_code}` }}</strong
            >
            <div class="ml-auto">
              <v-tooltip bottom outlined>
                <template #activator="{ on: onTooltip }">
                  <v-menu offset-y left v-on="onTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item"
                        outlined
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="{ ...onTooltip, ...on }"
                        :loading="btnLoadingPrint == item.id"
                      >
                        <v-icon> mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(itemLang, indexLang) in itemsLang"
                          :key="indexLang"
                          :title="
                            `In hợp đồng theo ${itemLang.title.toLowerCase()}`
                          "
                          @click="print(itemLang.key, itemLang.title, item.id)"
                        >
                          <div class="d-flex">
                            <v-img :src="itemLang.img"></v-img>
                            <v-list-item-title class="ml-2">{{
                              itemLang.title
                            }}</v-list-item-title>
                          </div>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </template>
                <span> In Hợp đồng</span>
              </v-tooltip>
            </div>
          </div>
          <v-row>
            <v-col cols="12" class="zero-vertical">
              <CustomTable
                class="mb-2"
                :headers="headers"
                :items="item.contract_addendum_details"
                hide-default-footer
                :page.sync="pagePL[index]"
                @change-page="(val) => (pagePL[index] = val)"
              >
                <template v-slot:[`item.product_image`]="{ item }">
                  <img
                    height="50px"
                    width="50px"
                    :src="
                      item.url_image
                        ? item.url_image
                        : item.image && getImageUrl(item.image.link)
                    "
                    v-if="item.image || item.url_image"
                  />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, index, 'UPDATE')"
                  >
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </CustomTable>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <v-dialog v-model="dialog" max-width="900px">
        <v-card>
          <v-card-title>
            <span class="headline">Cập nhật sản phẩm</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="form_detail">
                <v-row>
                  <v-col xs="6" sm="6" md="6">
                    <!-- :rules="formValidate.name" -->
                    <div class="label-form">Tên sản phẩm</div>
                    <v-text-field
                      placeholder="Tên sản phẩm"
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      v-model="editedItem.product_name"
                      :rules="product_nameRules"
                      hide-details="auto"
                      :readonly="editedItem.readonly"
                    ></v-text-field>
                  </v-col>
                  <v-col xs="6" sm="6" md="6">
                    <div class="label-form">Mã sản phẩm</div>
                    <v-text-field
                      placeholder="Mã sản phẩm"
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      v-model="editedItem.product_code"
                      disabled
                      hide-details="auto"
                      :readonly="editedItem.readonly"
                    ></v-text-field>
                  </v-col>
                  <v-col xs="6" sm="6" md="6">
                    <!-- :rules="formValidate.name" -->
                    <div class="label-form">Đơn vị tính</div>
                    <v-text-field
                      placeholder="Đơn vị tính"
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      v-model="editedItem.product_unit"
                      :rules="unitProductRules"
                      hide-details="auto"
                      :readonly="editedItem.readonly"
                    ></v-text-field>
                  </v-col>

                  <v-col xs="6" sm="6" md="6">
                    <!-- :rules="formValidate.name" -->
                    <div class="label-form">Đơn giá xử lý( VNĐ )</div>
                    <v-text-field
                      placeholder="Đơn giá xử lý( VNĐ )"
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      v-model="editedItem.product_price"
                      type="number"
                      hide-details="auto"
                      :readonly="editedItem.readonly"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col xs="6" sm="6" md="6">
                    <div class="label-form">VAT(%)</div>
                    <v-text-field
                      placeholder="Đơn giá mới"
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      v-model="editedItem.vat"
                      type="number"
                    ></v-text-field>
                  </v-col> -->
                  <v-col xs="12" sm="12" md="12">
                    <div class="d-flex flex-column" style="height: 100%">
                      <div class="label-form">Hình ảnh</div>
                      <ImageUpdate
                        width="100%"
                        height="200px"
                        v-model="editedItem"
                        :readonly="editedItem.readonly"
                      />
                    </div>
                  </v-col>

                  <v-col xs="12" sm="12" md="12">
                    <div class="label-form">Ghi chú</div>
                    <v-textarea
                      v-model="editedItem.description"
                      placeholder="Ghi chú"
                      outlined
                      dense
                      prepend-inner-icon="mdi-note"
                      no-resize
                      height="200px"
                      :readonly="editedItem.readonly"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-4" text @click="close"> Đóng </v-btn>
            <v-btn color="success" @click="save" v-if="editedItem.readonly">
              <v-icon left>mdi-plus</v-icon>
              Lưu
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { store, update } from "@/api/productdetail.api";
import JsonToFromData from "@/utils/JsonToFormData.js";
import { addContractAddendum, printContractAddendum } from "@/api/hopdong";
import { saveAs } from "file-saver";
export default {
  props: {
    listItem: { type: Array, default: () => [] },
    listItemNotUpdate: { type: Array, default: () => [] },
  },

  data: (vm) => ({
    pagePL: [],
    pageNPL: [],
    data: vm.listItem,
    dataNotUpdate: vm.listItemNotUpdate,
    editedItem: {},
    codeProductRules: [(v) => !!v || "Mã sản phẩm không thể bỏ trống"],
    nameProductRules: [(v) => !!v || "Tên sản phẩm không thể bỏ trống"],
    unitProductRules: [(v) => !!v || "Đơn vị tính không thể bỏ trống"],
    product_nameRules: [(v) => !!v || "Tên sản phẩm không thể bỏ trống"],
    headers: [
      // { text: "STT", width: "100", sortable: false },
      // { text: "Icon", value: "icon", sortable: false },
      {
        text: "Mã sản phẩm",
        align: "start",
        sortable: false,
        value: "product_code",
      },
      {
        text: "Tên sản phẩm",
        align: "start",
        sortable: false,
        value: "product_name",
      },
      { text: "ĐVT", value: "product_unit" },
      // { text: "VAT(%)", value: "vat" },
      { text: "Đơn giá", value: "product_price" },
      { text: "Hình ảnh", value: "product_image" },
      { text: "Ghi chú", value: "description" },
      { text: "Hành động", value: "actions", sortable: false },
    ],
    dialog: false,
    TABLE: "CONTRACT_ADDENDUM",
    btnLoadingPrint: -1,
    editType: null,
    indexDataEdit: -1,
    indexProduct: -1,
    itemsLang: [
      {
        title: "Tiếng Việt",
        img: require("../../../assets/images/lang/vietnam-flag-icon-32.png"),
        key: "vn",
      },
      {
        title: "Tiếng Anh",
        img: require("../../../assets/images/lang/united-states-of-america-flag-icon-32.png"),
        key: "en",
      },
      {
        title: "Tiếng Trung",
        img: require("../../../assets/images/lang/china-flag-icon-32.png"),
        key: "cn",
      },
      {
        title: "Tiếng Hàn",
        img: require("../../../assets/images/lang/south-korea-flag-icon-32.png"),
        key: "ko",
      },
    ],
  }),
  watch: {
    listItem(val) {
      this.pagePL = Array(val.length).fill(1);
    },
    listItemNotUpdate(val) {
      this.pageNPL = Array(val.length).fill(1);
    },
  },
  methods: {
    async addPL(item) {
      let data = {
        contract_id: item.contract_id,
        product_price_id: item.id,
        product: {
          ...item.product_price_detail,
        },
      };
      const res = await addContractAddendum(JsonToFromData(data));
      this.alert(true);
      this.$emit("onDone");
    },
    async print(language, key_title, id) {
      try {
        this.btnLoadingPrint = id;
        const res = await printContractAddendum(id, { language });
        if (res.isError) {
          this.$store.dispatch("toastAlert/error", "Lỗi in phụ lục hợp đồng");
          return;
        }
        saveAs(new Blob([res]), `Phụ lục hợp Dồng ${key_title}.docx`);
      } catch (error) {
        this.$store.dispatch("toastAlert/error", "Lỗi in phụ lục hợp đồng");
      } finally {
        this.btnLoadingPrint = -1;
      }
    },
    save() {
      if (this.editType == "ADD") {
        this.updateAddAddendumProduct();
      } else if (this.editType == "UPDATE") {
        this.updateAddendumProduct();
      }
      this.close();
    },
    async updateAddendumProduct() {
      if (this.editedItem.id) {
        let data = { ...this.editedItem };
        await update(JsonToFromData(data, null), this.editedItem.id);
        this.alert(false);
      }
      this.$set(
        this.data[this.indexDataEdit].contract_addendum_details,
        this.indexProduct,
        { ...this.editedItem }
      );
    },
    updateAddAddendumProduct() {
      this.$set(
        this.dataNotUpdate[this.indexDataEdit].product_price_detail,
        this.indexProduct,
        { ...this.editedItem }
      );
    },
    close() {
      this.dialog = false;
      this.editType = null;
      this.indexProduct = -1;
      this.indexDataEdit = -1;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },
    alert(isCreate) {
      this.$emit("on-done");
      this.$store.dispatch("toastAlert/success", `${isCreate ? "Thêm mới" : "Cập nhật"} thành công`);
    },
    editItem(item, index, type) {
      this.editType = type;
      this.indexDataEdit = index;
      if (type == "ADD") {
        this.indexProduct = this.dataNotUpdate[index].product_price_detail
          .map((x) => JSON.stringify(x))
          .indexOf(JSON.stringify(item));
      } else if (type == "UPDATE" || type == "READONLY") {
        this.indexProduct = this.data[index].contract_addendum_details
          .map((x) => JSON.stringify(x))
          .indexOf(JSON.stringify(item));
      }
      this.editedItem = Object.assign(item, {
        table: this.TABLE,
        readonly: type == "READONLY",
      });
      this.dialog = true;
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
  },
};
</script>
<style lang="scss" scoped></style>
